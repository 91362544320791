<template>
  <v-container fluid>
    <v-form :readonly="!canEditSettings">
      <v-card>
        <v-card-text>
          <v-row class="px-2 bg-blue" dense>
            <v-col cols="12" md="6">
              <v-subheader>Default sort for Locations:</v-subheader>
              <v-radio-group v-model="other.locationSort" row>
                <v-radio label="Code" value="code"></v-radio>
                <v-radio label="Name" value="name"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="px-2" dense>
            <v-col cols="12" md="6">
              <v-subheader>Location to be used to determine the zone of a trip:</v-subheader>
              <v-radio-group v-model="other.determineZoneBy" row>
                <v-radio label="Request Location" value="request"></v-radio>
                <v-radio label="Vehicle Location" value="vehicle"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="px-2 bg-blue" dense>
            <v-col cols="12">
              <v-subheader>Require attachment for the following trip and vehicle types:</v-subheader>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader>Trip Types</v-subheader>
              <div class="checkbox-wrapper">
                <v-checkbox
                  v-for="(type, i) in tripTypes"
                  :key="i"
                  class="checkbox"
                  :value="type.id"
                  v-model="other.requireAttachmentsTripTypes"
                  :label="type.name"
                  multiple
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader>Vehicle Types</v-subheader>
              <div class="checkbox-wrapper">
                <v-checkbox
                  v-for="(type, i) in vehicleTypes"
                  :key="i"
                  class="checkbox"
                  :value="type.id"
                  v-model="other.requireAttachmentsVehicleTypes"
                  :label="type.name"
                  multiple
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';

export default {
  inject: ['eventHub'],
  components: {},
  data: () => ({
    other: {
      locationSort: 'code',
      determineZoneBy: 'request',
      requireAttachmentsTripTypes: [],
      requireAttachmentsVehicleTypes: [],
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'users', 'usersById', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
  },
  created() {
    this.eventHub.$on('saveOtherConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveOtherConfig');
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.other) this.other = { ...this.other, ...this.trConfig.other };
    },
    async save() {
      try {
        const value = this.trConfig ? { ...this.trConfig, other: this.other } : { other: this.other };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.$myalert.success('Other Form Decisions saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
/* .checkbox-wrapper {
  display: flex;
  align-items: center !important;
}
.checkbox {
  margin-right: auto;
} */
</style>
